import {
  GetProposalsListPayload,
  GetProposalsSearchPayload,
  ProposalsState,
} from '@store/proposals/types';
import { ProposalsActionTypes } from '@store/proposals/actionTypes';

export const proposalsSetState = (payload: Partial<ProposalsState>) => ({
  type: ProposalsActionTypes.SET_STATE,
  payload,
});

export const proposalsGetProposalsList = (payload: GetProposalsListPayload) => ({
  type: ProposalsActionTypes.GET_PROPOSALS_LIST,
  payload,
});

export const proposalsGetProposalsSearch = (payload: GetProposalsSearchPayload) => ({
  type: ProposalsActionTypes.GET_PROPOSALS_SEARCH,
  payload,
});

export const proposalsGetProfileProposals = (payload: GetProposalsListPayload) => ({
  type: ProposalsActionTypes.GET_PROFILE_PROPOSALS,
  payload,
});
