import { fork } from 'redux-saga/effects';
import { profileGetProfileSaga } from '@store/profile/sagas/getProfile';
import { profileGetKycLinkSaga } from '@store/profile/sagas/verifyKyc';
import { profileGetBalanceSaga } from '@store/profile/sagas/getBalance';
import { profileBuyRPVTokenSaga } from '@store/profile/sagas/buyRPVTokenSaga';
import { profileGetRateSaga } from '@store/profile/sagas/getRateSaga';

export const profileEffects = [
  fork(profileGetProfileSaga),
  fork(profileGetKycLinkSaga),
  fork(profileGetBalanceSaga),
  fork(profileBuyRPVTokenSaga),
  fork(profileGetRateSaga),
];
