import React from 'react';
import { Text, CurrencyItem, TitleWithBack } from '@components';
import { useWeb3React } from '@web3-react/core';
import { CurrencyName } from '@types';
import { useRpvStore } from './hooks';
import { RpvStoreForm } from './components/RpvStoreForm';

import styles from './styles.module.scss';

export const RpvStore: React.FC = () => {
  const {
    account: address,
  } = useWeb3React();

  const {
    handleBuyRPVTokens, balanceBNB, balanceRPV, rate,
  } = useRpvStore();

  return (
    <section className={styles.container}>
      <div className={styles.titleOut}>
        <TitleWithBack />
      </div>
      <div className={styles.card}>
        <Text type="span" className={styles.cardTitle}>
          Your Assets
        </Text>
        <CurrencyItem value={balanceBNB} name={CurrencyName.ETH} />
        <CurrencyItem value={balanceRPV} name={CurrencyName.RPV} />

        <div className={styles.walletContainer}>
          <Text className={styles.addressLabel}>Wallet Address</Text>
          <div className={styles.addressContainer}>
            <Text type="span" className={styles.address}>
              {address}
            </Text>
          </div>
        </div>
        <RpvStoreForm
          onSubmit={handleBuyRPVTokens}
          rate={rate}
          balanceBNB={balanceBNB}
        />
      </div>
    </section>
  );
};
