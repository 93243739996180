import { getContract, getAddress, getDecimalsRPVToken } from '@api/ethereum';
import { contracts } from '@constants';
import { VotingFactoryAbi } from '@assets/abi';
import { convertStringToByteHex } from '@utils';
import BigNumber from 'bignumber.js';

function getContractVotingFactory() {
  return getContract(contracts.VotingFactory, VotingFactoryAbi);
}

export const getCreatingContractAddress = async (
  salt: string,
  duration: string,
  qtyVoters: number,
  minPercentageVoters: number,
):Promise<string> => {
  const contract = getContractVotingFactory();
  const address = await getAddress();

  return contract.methods.createVoting(
    0,
    salt,
    duration, qtyVoters, minPercentageVoters, salt,
  ).call({ from: address });
};

export const createVoting = async (
  hashProposal: string,
  duration: string,
  qtyVoters: number,
  minPercentageVoters: number,
  salt: string,
) => {
  const contract = getContractVotingFactory();
  const address = await getAddress();

  return contract.methods.createVoting(
    0,
    `0x${convertStringToByteHex(hashProposal)}`,
    duration,
    qtyVoters,
    minPercentageVoters,
    salt,
  ).send({ from: address });
};

export const getPriceCreateProposal = async (): Promise<string> => {
  const contract = getContractVotingFactory();
  const decimals = await getDecimalsRPVToken();
  const result = await contract.methods.createProposalRate().call();
  // it is not an error, we should get a price from a rate, it is smart contract logic
  return new BigNumber(10).pow(decimals)
    .multipliedBy(new BigNumber(10).pow(decimals))
    .div(result)
    .toFixed();
};

export const getPriceBuyVotingToken = async (): Promise<string> => {
  const contract = getContractVotingFactory();
  const decimals = await getDecimalsRPVToken();
  const result = await contract.methods.buyVotingTokenRate().call();
  // it is not an error, we should get a price from a rate, it is smart contract logic
  return new BigNumber(10).pow(decimals)
    .multipliedBy(new BigNumber(10).pow(decimals))
    .div(result)
    .toFixed();
};
