import {
  call, takeLeading, put, select,
} from 'redux-saga/effects';
import { sagaErrorHandler, peelBigNumber } from '@utils';
import {
  buyVoting, approveRPVToken, allowanceRPVToken, getPriceBuyVotingToken,
} from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalIteGet } from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { rpvDecimals } from '@constants';
import { toastInfo, toastClose, toastSuccess } from '@components';
import { ToastMessages } from '@types';

function* buyVotingTokenSaga() {
  try {
    const hash = yield select(proposalItemSelectors.getProp('hash'));
    const addressVoting = yield select(proposalItemSelectors.getProp('address'));

    const amount = 1;

    const allowanceRPV = yield call(allowanceRPVToken, addressVoting);
    const priceBuyVotingTokenInWei = yield call(getPriceBuyVotingToken);
    const priceBuyVotingTokenInEther = peelBigNumber(priceBuyVotingTokenInWei, rpvDecimals);

    if (+allowanceRPV < +priceBuyVotingTokenInEther) {
      toastInfo(ToastMessages.TRANSACTION_REQUEST);
      yield call(approveRPVToken, addressVoting);
      toastClose();
      toastSuccess(ToastMessages.TRANSACTION_SUCCESS);
    }

    toastInfo(ToastMessages.TRANSACTION_REQUEST);
    yield call(buyVoting, addressVoting, amount);
    toastClose();
    toastSuccess(ToastMessages.TRANSACTION_SUCCESS);

    yield put(proposalIteGet(hash));
  } catch (error) {
    sagaErrorHandler(error);
  }
}

export function* proposalItemBuyVotingTokenSaga() {
  yield takeLeading(
    ProposalItemActionTypes.BUY_VOTING_TOKEN,
    buyVotingTokenSaga,
  );
}
