import { ProposalType } from '@types';

export { CARDS_PER_PAGE } from './cardsperpage';

export const headers = [
  'Crypto Votings',
  'Land based votings',
  // 'Organisational Votings',
];

export const tabKeys = [
  ProposalType.CryptoVoting,
  ProposalType.LandBasedVoting,
  // ProposalType.OrganizationalVoting,
];
