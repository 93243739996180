import { getContract, getWeb3 } from '@api/ethereum';
import { contracts } from '@constants';
import { RPVSaleAbi } from '@assets/abi';

export const buyRpvSale = async (amount: number, address: string) => {
  const contract = getContract(contracts.RPVsale, RPVSaleAbi);
  return contract.methods.buyTokens(address).send({ from: address, value: amount });
};

export const getRateRpvSale = async (): Promise<string> => {
  const web3 = getWeb3();
  const contract = getContract(contracts.RPVsale, RPVSaleAbi);
  const result = await contract.methods.rate().call();
  return web3.utils.fromWei(result);
};
