import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import {
  Button,
  FormikInput,
  FormikSelect,
  DatePicker,
  toastError,
  TitleWithBack,
} from '@components';
import { ProposalType } from '@types';
import { useDispatch, useSelector } from 'react-redux';
import { proposalItemCreate, proposalItemGetPricesForVoting } from '@store/proposalItem/actionCreators';
import { proposalItemSelectors } from '@store/proposalItem/selectors';
import { profileSelectors } from '@store/profile/selectors';
import { profileGetBalance } from '@store/profile/actionCreators';
import {
  useCancelModal,
  useSuccessModal,
  useFinishModal,
  useLandAreaProposal,
} from './hooks';
import {
  initialValues,
  initialValuesWithImage,
  proposalSelectItems,
  validationSchema,
} from './constants';
import {
  ImageUpload,
  CustomFieldArray,
  // FileUpload,
} from './components';
import styles from './styles.module.scss';

// function parseCSV(csvText:unknown) {
//   // @ts-ignore
//   const lines = csvText.split('\n');
//   const headers = lines[0].split(',');
//   const data = [];

//   for (let i = 1; i < lines.length; i++) {
//     const line = lines[i].split(',');
//     const entry = {};

//     for (let j = 0; j < headers.length; j++) {
//       // @ts-ignore
//       entry[headers[j]] = line[j];
//     }

//     data.push(entry);
//   }

//   return data;
// }

const ProposalCreation: React.FC = () => {
  const dispatch = useDispatch();
  const [values, setValues] = useState(initialValuesWithImage);

  const { ModalSuccess } = useSuccessModal();

  const { openCancelModal, ModalCancel } = useCancelModal();

  const { areasOption, formatLand } = useLandAreaProposal();

  const priceForCreate = useSelector(
    proposalItemSelectors.getProp('priceForCreate'),
  );

  const isCreatingProposal = useSelector(
    proposalItemSelectors.getProp('isCreatingProposal'),
  );

  const balanceRPV = useSelector(
    profileSelectors.getProp('balanceRPV'),
  );

  useEffect(() => {
    dispatch(profileGetBalance());
    dispatch(proposalItemGetPricesForVoting());
  }, [dispatch]);

  const handleSendTransaction = useCallback(() => {
    if (+balanceRPV < +priceForCreate) {
      toastError("You don't have enough RPV tokens, please go to your wallet and buy them", { autoClose: 3000 });
    } else {
      dispatch(proposalItemCreate({
        ...values,
        proposalType: values.proposalType?.value
          ? ProposalType[values.proposalType?.value as ProposalType]
          : ProposalType.CryptoVoting,
        land: formatLand(values.land),
      }));
    }
  }, [balanceRPV, dispatch, formatLand, priceForCreate, values]);

  const { openFinishModal, ModalFinish } = useFinishModal(handleSendTransaction);

  // const onUpload = useCallback((data) => {
  //   const reader = new FileReader();
  //   reader.onload = (theFile) => {
  //     const base64Text = String(theFile?.target?.result).split(',')[1];
  //     const decodedText = atob(base64Text);
  //     const organizationEmployees = parseCSV(decodedText).map((el) => JSON.stringify(el));
  //     // @ts-ignore
  //     setValues((value) => ({
  //       ...value,
  //       organizationEmployees,
  //     }));
  //   };
  //   reader.readAsDataURL(data[0]);
  // }, []);

  const onUploadImage = useCallback((data) => {
    const reader = new FileReader();
    reader.onload = (theFile) => {
      const result = String(theFile?.target?.result).split(',');
      setValues((value) => ({
        ...value,
        imageMimeType: result[0],
        image: result[1],
      }));
    };
    reader.readAsDataURL(data);
  }, []);

  return (
    <div className={styles.container}>
      <TitleWithBack onBackClick={openCancelModal} />
      <ModalCancel />
      <ModalFinish />
      <ModalSuccess />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        isInitialValid
        onSubmit={(newValues) => {
          setValues((oldValues) => ({
            ...oldValues,
            ...newValues,
          }));
        }}
      >
        {(props) => {
          const proposalType = props.values.proposalType?.value;
          const isLandbased = proposalType === ProposalType.LandBasedVoting;
          // const isOrganizational = proposalType === ProposalType.OrganizationalVoting;

          const checkValues = () => {
            let result = props.isValid && values.image.length > 0;
            if (isLandbased) {
              result = result && props.values.land.value.length > 0;
            }
            // if (isOrganizational) {
            //   result = result && values.organizationEmployees.length > 0;
            // }
            return result;
          };

          const isValidAll = checkValues();

          return (
            <Form
              onSubmit={props.handleSubmit}
              className={styles.contentWrapper}
            >
              <div className={styles.selects}>

                <FormikSelect
                  isSelectType
                  label="Type of the Proposal"
                  name="proposalType"
                  items={proposalSelectItems}
                  className={styles.formikInputStyle}
                />
                {isLandbased && (
                <FormikSelect
                  isSelectType
                  placeholder="Select area"
                  label="Area for the Proposal"
                  name="land"
                  items={areasOption}
                  className={styles.formikInputStyle}
                />
                )}
              </div>
              <ImageUpload
                onChange={onUploadImage}
                className={styles.imageUploader}
              />
              <div className={styles.leftPart}>
                <div className={styles.inputOut}>
                  <div className={styles.inputOutLeft}>
                    <div className={styles.nameLine}>

                      <FormikInput
                        label="Proposal Name"
                        name="name"
                        inputType="text"
                        className={`${styles.formikInputStyle} ${styles.inputName}`}
                      />
                      <div className={styles.selectsDesk}>

                        <FormikSelect
                          isSelectType
                          label="Type of the Proposal"
                          name="proposalType"
                          items={proposalSelectItems}
                          className={styles.formikInputStyle}
                        />
                        {isLandbased && (
                        <FormikSelect
                          isSelectType
                          placeholder="Select area"
                          label="Area for the Proposal"
                          name="land"
                          items={areasOption}
                          className={styles.formikInputStyle}
                        />
                        )}
                      </div>
                    </div>
                    <div className={styles.containerDateTime}>
                      <DatePicker
                        label="Expiration Date"
                        placeholder="Date"
                        minDate={new Date()}
                        value={props.values.date}
                        onChange={(date) => props.setFieldValue('date', date)}
                        className={styles.datePickerStyle}
                      />
                      {/* <TimeSelect
                        value={props.values.time}
                        onChange={(time) => props.setFieldValue('time', time)}
                        className={styles.timeSelect}
                      /> */}
                    </div>
                    {/* <FormikInput
                      label="The expected amount of voteres"
                      name="qtyVoters"
                      inputType="number"
                      className={styles.formikInputStyle}
                      isPositive
                      isInteger
                    /> */}

                    {/* {isOrganizational && (
                      <FileUpload
                        className={styles.fileUpload}
                        onUpload={onUpload}
                      />
                    )} */}
                    {/* <FormikInput
                      label="The percentage of voters, needed to accept the proposal"
                      name="minPercentageVoters"
                      inputType="number"
                      className={styles.formikInputStyle}
                      isPositive
                      isInteger
                      isNotZero
                      max={100}
                    /> */}
                    <CustomFieldArray
                      name="keywords"
                      setField={props.setFieldValue}
                      keywords={props.values.keywords}
                      currentKeyword={props.values.currentKeyword}
                    />
                    <FormikInput
                      inputType="text"
                      name="description"
                      label="Describe your proposal"
                      isTextArea
                      className={styles.describe}
                    />
                  </div>
                </div>

                <div className={styles.btnGrpOut}>
                  <div className={styles.btnGrp}>
                    {/* <Button
                    theme={ButtonTheme.outline}
                    className={styles.btn}
                    onClick={openCancelModal}
                    >
                    Cancel
                  </Button> */}
                    <Button
                      className={styles.btn}
                      htmlType="submit"
                      disabled={!props.dirty || !isValidAll || isCreatingProposal}
                      isLoading={isCreatingProposal}
                      onClick={openFinishModal}
                    >
                      create proposal
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export { ProposalCreation };
