import { contracts } from '@constants';
import { PassportAbi } from '@assets/abi';
import { getAddress, getContract } from '@api/ethereum';

function getContractPassportFactory() {
  return getContract(contracts.Passport, PassportAbi);
}

export const getAccountPassport = async (): Promise<string|number> => {
  const contract = getContractPassportFactory();
  const address = await getAddress();

  return contract.methods.balanceOf(address).call();
};
