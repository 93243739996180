import { api } from '@api';
import { throwApiError } from '@utils';

export const getUniqueVoters = async (): Promise<string> => {
  const { data } = await api.get('/proposal/unique_voters_count');
  if (data.error) {
    throwApiError(data);
  }
  return data.total;
};

export const getIncome = async (): Promise<string> => {
  const { data } = await api.get('/bc-gate/income');
  if (data.error) {
    throwApiError(data);
  }
  return data.total;
};

export const getGasPayments = async (): Promise<string> => {
  const { data } = await api.get('/bc-gate/fee_spent');
  if (data.error) {
    throwApiError(data);
  }
  return data.amount;
};

export const getCreationPrice = async (): Promise<string> => {
  const { data } = await api.get('/bc-gate/proposal_creation_price');
  if (data.error) {
    throwApiError(data);
  }
  return data.price;
};

export const setCreationPrice = async (price: string): Promise<boolean> => {
  const { data } = await api.post('/bc-gate/proposal_creation_price', {
    price,
  });
  if (data.error) {
    throwApiError(data);
  }
  return data;
};

export const getVotingTokenPrice = async (): Promise<string> => {
  const { data } = await api.get('/bc-gate/voting_token_price');
  if (data.error) {
    throwApiError(data);
  }
  return data.price;
};

export const setVotingTokenPrice = async (price: string): Promise<string> => {
  const { data } = await api.post('/bc-gate/voting_token_price', {
    price,
  });
  if (data.error) {
    throwApiError(data);
  }
  return data;
};

export const getRpvTokenPrice = async (): Promise<string> => {
  const { data } = await api.get('/bc-gate/rpv_token_price');
  if (data.error) {
    throwApiError(data);
  }
  return data.price;
};

export const setRpvTokenPrice = async (price: string): Promise<string> => {
  const { data } = await api.post('/bc-gate/rpv_token_price', {
    price,
  });
  if (data.error) {
    throwApiError(data);
  }
  return data;
};

export const getGasLimit = async (): Promise<string> => {
  const { data } = await api.get('/bc-gate/contract/gas_limit');
  if (data.error) {
    throwApiError(data);
  }
  return data.limit;
};

export const setGasLimit = async (limit: string): Promise<string> => {
  const { data } = await api.post('/bc-gate/contract/gas_limit', {
    limit,
  });
  if (data.error) {
    throwApiError(data);
  }
  return data;
};

export const setAdminRole = async ({
  address,
  role,
}: {
  address: string;
  role: string;
}): Promise<string> => {
  const { data } = await api.post('/admin/change-user-role', {
    address,
    role,
  });
  if (data.error) {
    throwApiError(data);
  }
  return data;
};
