import { api } from '@api';
import { throwApiError } from '@utils';
import { Profile } from '@types';

export const getProfile = async (): Promise<Profile> => {
  const { data } = await api.get('/profile');

  if (data.error) {
    throwApiError(data);
  }

  return data;
};

export const getKycLink = async (): Promise<string> => {
  // const { data } = await api.get('/kyc/verify/link');
  // const data = process.env.REACT_APP_REPRESENT_PASSPORT_URL

  // if (data.error) {
  //   throwApiError(data);
  // }

  // return data.link;

  const link = process.env.REACT_APP_REPRESENT_PASSPORT_URL;
  if (link) {
    return link;
  }

  return '';
};
