import Web3 from 'web3';
import { passwordSign, contracts, decimalPlaces } from '@constants';
import { BigNumber } from 'bignumber.js';
import { RPTTokenAbi } from '@assets/abi';
import { ethers } from 'ethers';

export {
  getDecimalsRPVToken, getBalanceRPVToken, approveRPVToken, allowanceRPVToken,
} from './rpvToken';
export { buyRpvSale, getRateRpvSale } from './rpvSale';
export {
  createVoting, getPriceCreateProposal, getPriceBuyVotingToken, getCreatingContractAddress,
} from './votingFactory';
export {
  buyVoting,
  voteYes,
  voteNot,
  getStatsVoting,
  getParamsVoting,
  getBalanceVoting,
  getAllVoters,
  approveVoting,
} from './voting';

export const getWeb3 = () => {
  const web3 = new Web3(Web3.givenProvider);
  return web3;
};

export const getAddress = async (): Promise<string> => {
  const web3 = getWeb3();
  const accounts = await web3.eth.getAccounts();
  return accounts[0];
};

export const getChainId = async (): Promise<number> => {
  const web3 = getWeb3();
  const chainId = await web3.eth.getChainId();
  return chainId;
};

export const signPersonal = async (message: string): Promise<string> => {
  const web3 = getWeb3();
  const address = await getAddress();

  const provider = new ethers.providers.Web3Provider(window.ethereum);

  const signer = await provider.getSigner();
  return signer.signMessage(message);
  // console.log(signer, 'signer');
  // signer.sign()

  const signature = await web3.eth.personal.sign(
    web3.utils.utf8ToHex(message),
    address,
    passwordSign,
  );
  return signature;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getContract = (address: string, abi: any) => {
  const web3 = getWeb3();
  const contract = new web3.eth.Contract(abi, address);
  return contract;
};

export const getBalanceBnB = async (address: string): Promise<string> => {
  const web3 = getWeb3();
  const result = await web3.eth.getBalance(address);
  return new BigNumber(web3.utils.fromWei(result)).decimalPlaces(decimalPlaces).toString();
};

export const getBalanceRPT = async (address: string): Promise<string> => {
  const contract = getContract(contracts.RPTToken, RPTTokenAbi);

  const result = await contract.methods.balanceOf(address).call();
  const decimals = await contract.methods.decimals().call();

  return (
    new BigNumber(result)
      .div(new BigNumber(10).pow(decimals))
      .decimalPlaces(decimalPlaces)
      .toString()
  );
};
