import { call, takeLeading, select } from 'redux-saga/effects';
import { BigNumber } from 'bignumber.js';
import { toastInfo, toastClose, toastSuccess } from '@components';
import { sagaErrorHandler } from '@utils';
import { Unwrap } from '@types';
import { buyRpvSale, getAddress, getDecimalsRPVToken } from '@api/ethereum';
import { ProfileActionTypes } from '@store/profile/actionTypes';
import { buyRPVTokens } from '@store/profile/actionCreators';
import { profileSelectors } from '@store/profile/selectors';

function* buyRpvTokensSaga({ payload }: ReturnType<typeof buyRPVTokens>) {
  try {
    toastInfo('Buying RPV tokens process');

    const address: Unwrap<typeof getAddress> = yield call(getAddress);
    const decimals: Unwrap<typeof getDecimalsRPVToken> = yield call(getDecimalsRPVToken);
    const rate:Unwrap<string> = yield select(profileSelectors.getProp('currentRate'));

    const amount = new BigNumber(payload.count)
      .times(rate)
      .times(new BigNumber(10).pow(decimals))
      .toNumber();

    yield call(buyRpvSale, amount, address);
    toastClose();
    toastSuccess('Success');
  } catch (error) {
    toastClose();
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* profileBuyRPVTokenSaga() {
  yield takeLeading(ProfileActionTypes.BUY_RPV_TOKENS, buyRpvTokensSaga);
}
