import {
  call, takeLeading, put, all,
} from 'redux-saga/effects';
import { sagaErrorHandler } from '@utils';
import { Unwrap } from '@types';
import {
  getBalanceBnB,
  getBalanceRPVToken,
  getAddress,
  getBalanceRPT,
} from '@api/ethereum';
import { ProfileActionTypes } from '@store/profile/actionTypes';
import { profileSetState } from '@store/profile/actionCreators';

function* getBalanceAllSaga() {
  try {
    const address: Unwrap<typeof getAddress> = yield call(getAddress);
    const [
      balanceBNB,
      balanceRPT,
      balanceRPV,
    ] : [
      Unwrap<typeof getBalanceBnB>,
      Unwrap<typeof getBalanceRPT>,
      Unwrap<typeof getBalanceRPVToken>,
    ] = yield all([
      call(getBalanceBnB, address),
      call(getBalanceRPT, address),
      call(getBalanceRPVToken, address),
    ]);

    yield put(
      profileSetState({
        balanceRPT,
        balanceRPV,
        balanceBNB,
      }),
    );
  } catch (error) {
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* profileGetBalanceSaga() {
  yield takeLeading(ProfileActionTypes.GET_BALANCE, getBalanceAllSaga);
}
