import { FormikSelectItem } from '../../../components/FormikSelect';

type InitialValues = {
  name: string;
  duration: string;
  // qtyVoters: string;
  proposalType?: FormikSelectItem,
  land: {
    label: string,
    value: string,
  },
  // minPercentageVoters: string;
  keywords: string[];
  description: string;
  currentKeyword: string;
  date?: Date;
  time?: string;
  expiresAt: string
};

export const initialValues: InitialValues = {
  name: '',
  duration: '',
  // qtyVoters: '',
  proposalType: undefined,
  land: {
    label: '',
    value: '',
  },
  // minPercentageVoters: '',
  keywords: [],
  description: '',
  currentKeyword: '',
  date: undefined,
  time: '00:00',
  expiresAt: '',
};

type InitialValuesWithImage = InitialValues & {
  image: string;
  imageMimeType: string;
  organizationEmployees: string;
};

export const initialValuesWithImage: InitialValuesWithImage = {
  name: '',
  duration: '',
  // qtyVoters: '',
  proposalType: undefined,
  land: {
    label: '',
    value: '',
  },
  // minPercentageVoters: '',
  keywords: [],
  description: '',
  currentKeyword: '',
  date: undefined,
  time: '00:00',

  image: '',
  imageMimeType: '',
  organizationEmployees: '',
  expiresAt: '',
};
