import { ethers } from 'ethers';
import { getContract, getAddress } from '@api/ethereum';
import { VotingAbi } from '@assets/abi';

function getContractVoting(addressVoting: string) {
  return getContract(addressVoting, VotingAbi);
}

export const buyVoting = async (addressVoting: string, amount: number) => {
  const contract = getContractVoting(addressVoting);
  const address = await getAddress();

  return contract.methods.buy(amount).send({ from: address });
};

export const voteYes = async (addressVoting: string) => {
  const contract = getContractVoting(addressVoting);
  const address = await getAddress();

  return contract.methods.voteFor().send({ from: address });
};

export const voteNot = async (addressVoting: string) => {
  const contract = getContractVoting(addressVoting);
  const address = await getAddress();

  return contract.methods.voteAgainst().send({ from: address });
};

export const getStatsVoting = async (addressVoting: string): Promise<{
  _count: string,
  _for: string,
}> => {
  const contract = getContractVoting(addressVoting);

  return contract.methods.getStats().call();
};

export const getParamsVoting = async (addressVoting: string) => {
  const contract = getContractVoting(addressVoting);

  return contract.methods.params().call();
};

export const getBalanceVoting = async (addressVoting: string): Promise<string> => {
  const contract = getContractVoting(addressVoting);
  const address = await getAddress();

  return contract.methods.balanceOf(address).call();
};

export const getAllVoters = async (addressVoting: string): Promise<string[]> => {
  const contract = getContractVoting(addressVoting);

  return contract.methods.getAllVoters().call();
};

export const approveVoting = async (addressVoting: string) => {
  const contract = getContractVoting(addressVoting);
  const address = await getAddress();
  return contract.methods
    .approve(addressVoting, ethers.constants.MaxUint256)
    .send({ from: address });
};
