import React, { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import {
  Text, Button, TitleWithBack, ImageIpfs,
} from '@components';
import { ButtonTheme, Colors, ProposalType } from '@types';
import { formatTimeDifference } from '@utils';
import Loader from 'react-loader-spinner';
import {
  Tags, BuyModal, VoteModal, SuccessModal,
} from './components';
import { StatusVote } from './types';
import { useBuy, useVote } from './hooks';

import styles from './styles.module.scss';

const ProposalItem = () => {
  const {
    isShowBuyModal, closeBuy, handleBuy, showBuy,
  } = useBuy();
  const {
    isShowVoteModal,
    closeVote,
    showVote,
    status,
    // percentProgressVoting,
    // totalVotersNeed,
    // countAllVoting,
    // minCountVoters,
    // percentYes,
    name,
    // stats,
    // isVotingLegitimate,
    description,
    keywords,
    // creator,
    proposalType,
    handleVoteYes,
    handleVoteNot,
    imageHash,
    isVoting,
    // expirationDateFormatted,
    expiresAtFormatted,
    isExpired,
    votedAgainst = '0',
    votedFor = '0',
    votedTotal = '0',
    isFetching,
  } = useVote();

  const [timeRemain, setTimeRemain] = useState('');
  const handleVote = useCallback(() => {
    if (status === StatusVote.notBalance || status === StatusVote.notBalanceRPV) {
      showBuy();
    } else {
      showVote();
    }
  }, [showBuy, showVote, status]);

  useEffect(() => {
    const t = setInterval(() => {
      const timeLeft = new Date(expiresAtFormatted).getTime() - Date.now();

      setTimeRemain(formatTimeDifference(timeLeft));
    }, 1000);

    return () => clearInterval(t);
  }, [expiresAtFormatted]);
  // const { against, for: forStat, total } = stats;
  return (
    <div className={cx(styles.proposalItem)}>
      <TitleWithBack />

      {!isFetching ? (
        <div className={styles.containerDetails}>
          <div className={`${styles.titleLine} ${styles.mob}`}>
            <div className={styles.title}>{name}</div>
            <div>
              <div className={styles.type}>{proposalType === ProposalType.LandBasedVoting ? 'Land-based' : 'Crypto'}</div>
              <div className={styles.time}>{timeRemain}</div>
            </div>
          </div>
          <div className={styles.containerImg}>
            <ImageIpfs
              imageHash={imageHash}
              className={styles.img}
            />
          </div>
          <div className={styles.containerInfo}>
            <div className={`${styles.titleLine} ${styles.desc}`}>
              <div className={styles.title}>{name}</div>
              <div>
                <div className={styles.type}>{proposalType === ProposalType.LandBasedVoting ? 'Land-based' : 'Crypto'}</div>
                <div className={styles.time}>{timeRemain}</div>
              </div>
            </div>
            <Text type="h3" className={styles.textDescription}>
              {description}
            </Text>

            <Tags labels={keywords} />

            <div className={styles.progressWrapper}>
              { +votedTotal
                ? (

                  <div className={styles.progress}>
                    <div className={styles.stat}>
                      Yes
                      {`(${((+votedFor / +votedTotal) * 100).toFixed(0)}%)`}
                    </div>
                    <div className={styles.stat}>
                      No
                      {`(${((+votedAgainst / +votedTotal) * 100).toFixed(0)}%)`}
                    </div>
                    <div className={styles.progressBar} style={{ width: `${(+votedFor / +votedTotal) * 100}%` }} />
                  </div>

                ) : (
                  <div className={styles.progress}>
                    <div className={styles.stat}>
                      Yes (0%)
                    </div>
                    <div className={styles.stat}>
                      No (0%)
                    </div>
                    <div className={styles.progressBar} style={{ width: '50%' }} />
                  </div>
                )}
            </div>
            {/*
          <div className={styles.containerNeedAccept}>
            <Text className={styles.textNeedAccept}>
              Needed to accept the voting:
            </Text>
            <Text type="p" className={styles.textValue}>
              {`${minCountVoters} votes`}
            </Text>
          </div> */}

            {/* {isVotingLegitimate && (
            <Text type="p" className={styles.textLegitimate}>
              Voting legitimate!
            </Text>
          )}

          {countAllVoting > 0 && (
            <VotesResult percentYes={percentYes} />
          )} */}
            {/* StatusVote */}
            {!isVoting
          && !isExpired
          && status !== StatusVote.notBalance
            && status !== StatusVote.notBalanceRPV && (
            <div className={styles.containerActions}>

              <Button
                className={styles.voteForBtn}
                disabled={isExpired}
                onClick={handleVoteYes}
              >
                vote for
              </Button>

              <Button
                disabled={isExpired}
                theme={ButtonTheme.outline}
                className={styles.voteAgainstBtn}
                onClick={handleVoteNot}
              >
                vote against
              </Button>
            </div>
            )}
            {!isVoting
          && !isExpired
          && (status === StatusVote.notBalance || status === StatusVote.notBalanceRPV) && (
            <div className={styles.containerActions}>

              <Button
                disabled={isExpired}
                theme={ButtonTheme.outline}
                className={styles.voteAgainstBtn}
                onClick={showBuy}
              >
                Buy voting tokens
              </Button>
            </div>
            )}

            {isExpired && (
            <div className={styles.containerActionsSingle}>
              <Button
                className={styles.btnSingle}
                disabled
                onClick={handleVote}
              >
                the proposal has expired
              </Button>
            </div>
            )}

            {isVoting && (
            <div className={styles.containerActionsSingle}>
              <Button
                disabled
                onClick={() => {}}
                className={styles.btnSingle}
              >
                you’ve already voted
              </Button>
            </div>
            )}

            {/* {isVoting && (
            <Button
              disabled
              onClick={() => {}}
              className={styles.buttonAlreadyVoted}
            >
              You have already voted
            </Button>
          )}

          {status === StatusVote.finished && (
            <Text type="p" className={styles.textUnfortunately}>
              Unfortunately you cant vote. The proposal is finished
            </Text>
          )}

          {proposalType === ProposalType.LandBasedVoting && (
            <div className={styles.containerCreator}>
              <Text type="p" className={styles.textCreator}>
                Creator:
              </Text>
              <Text type="p" className={styles.textValue}>
                {creator}
              </Text>
            </div>
          )}

          <div className={styles.separator} />

          <Text type="h3" className={styles.titleDetails}>
            proposal description
          </Text>

          <Text type="p" className={styles.textDescription}>
            {description}
          </Text>

          <div className={styles.containerLimit}>
            <Text className={styles.textLimit}>
              Expiration Date:
            </Text>
            <Text className={styles.textLimitValue}>
              {expiresAtFormatted || expirationDateFormatted}
            </Text>
          </div> */}
          </div>
        </div>
      ) : (

        <div className={styles.loaderContainer}>
          <Loader
            type="Rings"
            color={Colors.gold}
            height={100}
            width={100}
            timeout={0}
          />
        </div>

      )}
      <BuyModal
        isOpen={isShowBuyModal}
        onClose={closeBuy}
        onBuy={handleBuy}
        name={name}
        status={status}
      />
      <VoteModal
        isOpen={isShowVoteModal}
        onClose={closeVote}
        status={status}
        name={name}
        onVoteNot={handleVoteNot}
        onVoteYes={handleVoteYes}
      />
      <SuccessModal />
    </div>
  );
};

export { ProposalItem };
