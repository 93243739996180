import { fork } from 'redux-saga/effects';
import { proposalItemGetProposalSaga } from '@store/proposalItem/sagas/getProposal';
import { proposalItemBuyVotingTokenSaga } from '@store/proposalItem/sagas/buyVotingToken';
import { proposalItemVoteSaga } from '@store/proposalItem/sagas/vote';
import { proposalItemCreateSaga } from '@store/proposalItem/sagas/createProposal';
import { proposalItemGetPricesForVotingSaga } from '@store/proposalItem/sagas/getPricesForVoting';

export const proposalItemEffects = [
  fork(proposalItemGetProposalSaga),
  fork(proposalItemBuyVotingTokenSaga),
  fork(proposalItemVoteSaga),
  fork(proposalItemCreateSaga),
  fork(proposalItemGetPricesForVotingSaga),
];
