import React from 'react';
import classNames from 'classnames/bind';
import { Text, Link } from '@components';
import { FooterTheme } from '@types';
import facebook from '@assets/images/home/facebook.svg';
import twitter from '@assets/images/home/twitter.svg';
import telegram from '@assets/images/home/telegram.svg';
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

type FooterProps = {
  className?: string;
  theme?: FooterTheme;
};

const Footer = ({
  className = '',
  theme = FooterTheme.default,
}: FooterProps) => (
  <footer className={cx(styles.footer, className)}>
    <div className={cx(styles.content)}>

      <div className={styles.containerItems}>
        <Text
          className={cx(styles.title, {
            blackColor: theme === FooterTheme.default,
            whiteColor: theme === FooterTheme.white,
          })}
        >
          Follow us:
        </Text>
        <div className={styles.socialLinks}>
          <Link
            href="/"
            key="telegram"
          >
            <img src={telegram} alt="" />
          </Link>
          <Link
            href="/"
            key="facebook"
          >
            <img src={facebook} alt="" />
          </Link>
          <Link
            href="/"
            key="twitter"
          >
            <img src={twitter} alt="" />
          </Link>
        </div>

      </div>
    </div>
  </footer>
);

export { Footer };
