import { App } from '@containers';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ToastPortal } from '@components';
import './styles/globals.css';

import { store, persistor } from './store';
import './initialImports';

import * as serviceWorker from './serviceWorker';

const root = document.getElementById('root');

ReactDOM.render(
  <>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
        <ToastPortal />
      </PersistGate>
    </Provider>
  </>,
  root,
);

serviceWorker.unregister();
