import {
  call, takeLeading, put,
} from 'redux-saga/effects';
import { Unwrap } from '@types';
import { sagaErrorHandler, peelBigNumber } from '@utils';
import { getPriceCreateProposal } from '@api/ethereum';
import { ProposalItemActionTypes } from '@store/proposalItem/actionTypes';
import { proposalItemSetState } from '@store/proposalItem/actionCreators';
import { rpvDecimals } from '@constants';

function* getPriceForVotingSaga() {
  try {
    const priceForCreateInWei: Unwrap<typeof getPriceCreateProposal> = yield call(
      getPriceCreateProposal,
    );
    const priceForCreateInEther = peelBigNumber(priceForCreateInWei, rpvDecimals);

    yield put(
      proposalItemSetState({
        priceForCreate: priceForCreateInEther,
      }),
    );
  } catch (error) {
    sagaErrorHandler(error);
  }
}

export function* proposalItemGetPricesForVotingSaga() {
  yield takeLeading(
    ProposalItemActionTypes.GET_PRICES_FOR_VOTING,
    getPriceForVotingSaga,
  );
}
