import { colors, mediaQueries } from '@constants';
import styled from '@emotion/styled';

export const AccountAddressStyled = styled('div')`
  padding: 10px 36px;
  line-height: 1;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: lowercase;
  border: 1px solid ${colors.gold};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-radius: 10px;
  ${mediaQueries.lg} {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
`;
