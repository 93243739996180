import React, { useMemo, useCallback } from 'react';
import { Modal } from '@components';
import {
  useHistory,
} from 'react-router-dom';
import { ButtonTheme, RouteName } from '@types';
import { StatusVote } from '../../types';
import styles from './styles.module.scss';

type PropsBuyModal = {
  isOpen: boolean;
  name: string;
  onClose: () => void;
  onBuy: () => void;
  status: StatusVote;
};

const BuyModal = ({
  isOpen, onClose, onBuy, name, status,
}: PropsBuyModal) => {
  const history = useHistory();

  const description = useMemo(() => {
    if (status === StatusVote.notBalanceRPV) {
      return `You don’t have enough RPV in your wallet to buy a voting token.
      Buy more RPV and return to proposal!`;
    }
    return 'Do you want to buy a voting token for this proposal?';
  }, [status]);

  const handleGotoBuyRpvToken = useCallback(() => {
    history.push(RouteName.proposalStore);
  }, [history]);

  const buttons = useMemo(() => {
    if (status === StatusVote.notBalanceRPV) {
      return [
        {
          children: 'buy RPV',
          onClick: handleGotoBuyRpvToken,
          key: 'buy',
        },
        {
          children: 'Return to proposal',
          theme: ButtonTheme.outline,
          onClick: onClose,
          key: 'no',
        },
      ];
    }
    return [
      {
        children: 'Yes',
        key: 'yes',
        onClick: onBuy,
        className: styles.buttonBuy,
      },
      {
        children: 'No',
        theme: ButtonTheme.outline,
        onClick: onClose,
        key: 'no',
        className: styles.button,
      },
    ];
  }, [status, onClose, onBuy, handleGotoBuyRpvToken]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Proposal ${name}`}
      description={description}
      buttons={buttons}
      className={styles.modal}
      descriptionName={styles.description}
      buttonsName={styles.containerButtons}
    />
  );
};

export { BuyModal };
