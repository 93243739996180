import React, {
  FC, ReactElement, useEffect, useState,
} from 'react';

import { HeaderTheme, RouteName, StatusHeader } from '@types';
import { useAuth } from '@hooks';
import { logoFull, logoMob } from '@assets/images';
import { useGetScroll } from '../../hooks/useGetScroll';
import { ConnectButton } from '../connect-button';
import { AccountAddress } from '../account-address';
import { Nav } from './components';
import {
  AddressMob,
  Burger, ConnectButtonWrapper, Content, LogoDesk, LogoMob, Wrapper,
} from './Header.styled';

export const Header: FC<{
  status: StatusHeader;
  theme?: HeaderTheme;
  address?:string|null
  isAdmin?:boolean
  className?:unknown
}> = ({ status }): ReactElement => {
  const { account } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const isScrolled = useGetScroll();
  const isConnected = status === StatusHeader.connected;

  const onHandleMenuOpen = () => {
    setIsMenuOpen((prev) => !prev);
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <Wrapper isScrolled={isScrolled} isOpen={isMenuOpen}>
      <Content>
        <LogoMob to={RouteName.home}>
          <img
            src={logoMob}
            alt="logo"
          />
        </LogoMob>
        <LogoDesk to={RouteName.home}>
          <img
            src={logoFull}
            alt="logo"
            className="logoFull"
          />
        </LogoDesk>
        <Nav open={isMenuOpen} />
        {mounted && isConnected && account && (
          <AddressMob>
            <AccountAddress>{account}</AccountAddress>
          </AddressMob>
        )}
        <Burger open={isMenuOpen} onClick={onHandleMenuOpen}>
          <span />
        </Burger>
        <ConnectButtonWrapper>
          {mounted && isConnected && account ? (
            <AccountAddress>{account}</AccountAddress>
          ) : (
            <ConnectButton />
          )}
        </ConnectButtonWrapper>
      </Content>
    </Wrapper>
  );
};
