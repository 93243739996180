import {
  call, takeLeading, put,
} from 'redux-saga/effects';
import { Unwrap } from '@types';
import { ProfileActionTypes } from '@store/profile/actionTypes';
import { sagaErrorHandler } from '@utils';
import { getRateRpvSale } from '@api/ethereum';
import { profileSetState } from '@store/profile/actionCreators';

function* getRateSaga() {
  try {
    const rate: Unwrap<typeof getRateRpvSale> = yield call(getRateRpvSale);
    yield put(
      profileSetState({
        currentRate: rate,
      }),
    );
  } catch (error) {
    // @ts-ignore
    sagaErrorHandler(error);
  }
}

export function* profileGetRateSaga() {
  yield takeLeading(ProfileActionTypes.GET_RATE, getRateSaga);
}
