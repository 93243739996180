import { api, getUrlApi } from '@api';
import { AccessTokenResponse } from '@types';
import { throwApiError } from '@utils';

export const getVersionAuth = async (): Promise<string> => {
  const { data } = await api.get('auth/internal/version');

  if (data.error) {
    throwApiError(data);
  }

  return data.version;
};

export const confirm = async (
  signed: string,
  address: string,
): Promise<AccessTokenResponse> => {
  const { data } = await api.post('auth/wallet-login', {
    signed,
    wallet: address,
    // visitorId: process.env.REACT_APP_VISITOR_ID || '12345678-1234-5678-1234-567812345678',
  });

  if (data.error) {
    throwApiError(data);
  }

  const {
    expiresIn,
    token,
  } = data;

  return {
    expiresIn,
    token,
  };
};

export const signOut = async () => {
  const { data } = await api.delete('auth/signout');

  if (data.error) {
    throwApiError(data);
  }
};

export const refresh = async (
  refreshTokenOld: string,
): Promise<AccessTokenResponse> => {
  const response = await fetch(`${getUrlApi()}/auth/refresh`, {
    headers: {
      Authorization: `Bearer ${refreshTokenOld}`,
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Origin: '*',
    },
  });

  const data = await response.json();

  if (data.error) {
    throwApiError(data);
  }

  const {
    expiresIn,
    token,
  } = data;

  return {
    expiresIn,
    token,
  };
};
