import React from 'react';
import cx from 'classnames';
import { Text } from '@components';
import { CurrencyName } from '@types';
import styles from './styles.module.scss';

export type CurrencyItemProps = {
  value: string;
  name: CurrencyName;
  className?: string;
};

export const CurrencyItem: React.FC<CurrencyItemProps> = ({
  value,
  name,
  className,
}: CurrencyItemProps) => (
  <div className={cx(styles.currencyContainer, className)}>
    <Text type="h3" className={cx(styles.currency, styles.currencyQuantity)}>
      {value}
    </Text>
    <Text type="h3" className={styles.currency}>
      {name}
    </Text>
  </div>
);
